

































































































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import RequestHandler from "@/assets/ts/requestHandler.ts";
import { SearchHistoryEntry } from "@/assets/classes/SearchHistory.ts";

const RH = new RequestHandler();
@Component
export default class ReportGenerator extends Vue {
  @Prop({ required: true }) searchTime!: Date;
  @Prop({ required: false }) reportType!: string;
  @Prop({ required: true }) startDate!: string;
  @Prop({ required: true }) endDate!: string;
  private employees = [];
  private searches: SearchHistoryEntry[] = [];
  private employerName = this.$store.getters.userInfo.username;
  private employerMVDSCustomerID = 0;
  private moneyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });

  get achSearches() {
    return this.searches.filter(search => {
      return search.tenderTypeCode == "8";
    });
  }

  get creditCardSearches() {
    return this.searches.filter(search => {
      return search.tenderTypeCode == "10";
    });
  }

  get total() {
    let total = 0;
    this.searches.forEach(search => {
      total += search.chargedAmount;
    });
    return this.moneyFormatter.format(total);
  }

  get achTotal() {
    let total = 0;
    this.achSearches.forEach(search => {
      total += search.chargedAmount;
    });
    return this.moneyFormatter.format(total);
  }

  get creditCardTotal() {
    let total = 0;
    this.creditCardSearches.forEach(search => {
      total += search.chargedAmount;
    });
    return this.moneyFormatter.format(total);
  }

  private async setEmployees(): Promise<void> {
    try {
      this.employees = await RH.getEmployees(this.employerMVDSCustomerID);
      this.employees = this.employees.filter(
        employee =>
          employee["user"]["id"] != this.$store.getters.userInfo.userID
      );
      this.employees.forEach((employee: any) => {
        if (employee["statusCode"] == "D") {
          employee["disabledByAdmin"] = true;
        } else {
          employee["disabledByAdmin"] = false;
        }
      });
    } catch (e) {
      this.$root.$emit("snackbar-message", "Employees have failed to populate");
    }
  }

  private async setSearches() {
    if (Date.parse(this.startDate) <= Date.parse(this.endDate)) {
      try {
        const response = await RH.getSearchesRange(
          this.employerMVDSCustomerID,
          this.startDate,
          this.endDate
        );
        this.searches = [];
        if (response && response.length > 0) {
          response.forEach(
            (historyEntry: { [key: string]: any } | undefined) => {
              this.searches.push(new SearchHistoryEntry(historyEntry));
            }
          );
        }
      } catch (e) {
        this.$root.$emit(
          "snackbar-message",
          "Searches have failed to populate"
        );
      }
    } else {
      this.$root.$emit(
        "snackbar-message",
        "The Start Date must be before the End Date"
      );
    }
  }

  private async reportSetup() {
    this.$root.$emit("start-loading");
    await this.setEmployees();
    await this.setSearches();
    this.$root.$emit("stop-loading");
  }

  private async setMVDSCustomerID() {
    const userID = this.$store.getters.userInfo.userID;
    this.employerMVDSCustomerID = (
      await RH.getUserCustomerInfo(userID)
    ).mvdsCustomerID;
  }

  async created() {
    this.$root.$on("reload-report", async () => {
      await this.setMVDSCustomerID();
      await this.reportSetup();
    });
    try {
      await this.setMVDSCustomerID();
    } catch {
      this.$root.$emit("snackbar-message", "Failed to retrieve customer ID");
    }
    await this.reportSetup();
  }
}
