








































































































import { Component, Vue } from "vue-property-decorator";
import RequestHandler from "@/assets/ts/requestHandler.ts";
import ReportGenerator from "@/components/ReportGenerator.vue";

const RH = new RequestHandler();
@Component({
  components: {
    ReportGenerator
  }
})
export default class Reports extends Vue {
  private reports = {
    dialog: false,
    type: "Employees",
    typesBoxItems: ["Employees", "Billing"]
  };
  private searchTime = new Date();
  private startDate = new Date().toISOString().substr(0, 10);
  private endDate = new Date().toISOString().substr(0, 10);
  private startDatePicker = false;
  private endDatePicker = false;

  private printReport() {
    window.print();
  }
}
