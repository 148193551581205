export class SearchHistoryEntry {
  constructor(historyEntry: { [key: string]: any } = {}) {
    this.searchID = historyEntry.searchID;
    this.searchDate = new Date(historyEntry.searchDate).toLocaleDateString();
    this.vin = historyEntry.vin;
    if (this.vin) this.vin = this.vin.toUpperCase();
    this.plateNo = historyEntry.plateNo;
    if (this.plateNo) this.plateNo = this.plateNo.toUpperCase();
    this.chargedAmount = historyEntry.chargedAmount;
    this.tenderTypeCode = historyEntry.tenderTypeCode;
    this.statusCode = historyEntry.statusCode;
    this.stolen = false;
    this.nic.id = historyEntry.nicID;
    this.nic.lawEnforcementAgency = historyEntry.lawEnforcementAgency;
    this.nic.theftDate = historyEntry.theftDate;
    this.nic.completionDate = historyEntry.nicComplete;
    this.stolen =
      historyEntry.statusCode === "H" || historyEntry.statusCode === "S";
    this.showPrintButton = !this.stolen;

    this.nic.complete = false;
    if (![null, ""].includes(this.nic.completionDate)) {
      this.nic.complete = true;
    }

    if (historyEntry.searchResult?.vehicleRecord) {
      if (
        Object.prototype.hasOwnProperty.call(
          historyEntry.searchResult.vehicleRecord,
          "vehicle"
        )
      ) {
        const vehicleData = historyEntry.searchResult.vehicleRecord.vehicle;
        if (this.vin == "") {
          this.vin = vehicleData.vin;
        }
        this.make = vehicleData.make;
        this.model = vehicleData.model;
        this.year = vehicleData.year;
      } else {
        this.make = "";
        this.model = "";
        this.year = "";
      }

      if (
        Object.prototype.hasOwnProperty.call(
          historyEntry.searchResult.vehicleRecord,
          "plate"
        )
      ) {
        const plateData = historyEntry.searchResult.vehicleRecord.plate;
        if (this.plateNo === "") {
          this.plateNo = plateData.plate;
        }
      }
    } else {
      this.make = "";
      this.model = "";
      this.year = "";
      this.showPrintButton = false;
    }
    this.userID = historyEntry.userID;
    this.username = historyEntry.username;
  }
  searchID: number;
  searchDate: string;
  vin: string;
  plateNo: string;
  make: string;
  model: string;
  year: string;
  userID: number;
  username: string;
  showPrintButton: boolean;
  chargedAmount: number;
  tenderTypeCode: string;
  statusCode: string;
  stolen: boolean;
  nic = {
    id: "",
    lawEnforcementAgency: "",
    theftDate: "",
    completionDate: "",
    complete: false
  };
}
